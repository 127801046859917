import axios from 'axios'

const csrfElement = document.getElementsByName('csrf-token')
const token = csrfElement.length > 0 ? csrfElement[0].getAttribute('content') : ''

const internalAPI = axios.create({
  headers: {
    'X-CSRF-Token': token,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

export default internalAPI
